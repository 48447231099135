<template>
  <div>
    <VueUnity :unity="unityContext" width="1920" height="1080" />
  
  
    <div class="section debugger m-5 is-size-4">
      <h3 class="is-title is-size-1 has-text-weight-bold py-5">External debugger</h3>

      {{ $store.state.space.players.length }} Jugadores

      <pre class="mb-5">{{ controller }}</pre>

      <pre v-if="controller.type == 'leftRight'">
        {{leftRightCount}}
      </pre>

      <div class="jugadores">
        <div v-if="$store.state.space.players.length == 0" class="has-text-weight-bold">Waiting players...</div>
        <div v-for="player in $store.state.space.players.slice(0,6)" :key="player.dbid">
          <div>
            <div
              class="player-label p-3" 
              :style="{ '--player-color': '#'+player.color }">
              <player-avatar :key="player.dbid" :player="player" :scale="1.5" :showUsername="false" />
              <div>
                <span class="has-text-weight-bold">{{ player.username }}</span><span v-if="player.role=='tour-leader'">: Tour Leader</span>
              </div>
            </div>
            
            <pre v-if="controller.type == 'joystick'">{{ player.joystick ? player.joystick : 'Waiting data...'  }}</pre>
            <pre v-if="controller.type == 'gyro'">{{ player.gyro ? player.gyro : 'Waiting data...' }}</pre>
            <pre v-if="controller.type == 'leftRight'">{{ player.leftRight ? player.leftRight : 'Waiting data...' }}</pre>
          </div> 
        </div>
        <div v-if="$store.state.space.players.length > 6">Y {{$store.state.space.players.length-6}} jugadores más</div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerAvatar from '../PlayerAvatar.vue';

import UnityWebgl from 'unity-webgl';
import VueUnity from 'unity-webgl/vue';
// Links de ejemplos de Unity webgl vue
// https://github.com/Meqn/UnityWebGL.js
// https://stackblitz.com/edit/unity-webgl-vue2-demo?file=src%2FApp.vue

const Unity = new UnityWebgl({
  loaderUrl:
    '/unity/loader.js',
  dataUrl:
    'http://192.168.0.144:3000/media/files/VictratoWebGLTest.data.unityweb',
  frameworkUrl:
    '/unity/framework.js.unityweb',
  codeUrl:
    '/unity/wasm.unityweb',
  streamingAssetsUrl: "http://192.168.0.144:3000/media/files/"
});


// Unity.on('device', () => alert('click device ...'));
// eslint-disable-next-line
// Unity.on('progress', (percent) => console.log('Unity progress: ', percent))
// eslint-disable-next-line
Unity.on('loaded', (percent) => console.log('Unity loaded: success'))
// eslint-disable-next-line
  .on('created', (percent) => console.log('Unity created: success'));


export default {
  components: {
    PlayerAvatar,
    VueUnity,
  },
  data() {
    return {
      controller: undefined,
      leftRightCount: {left:0,right:0},
      leftRightAvg: 0,
      unityContext: Unity,
    };
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    joystick() {
      return this.$store.state.space.players[0].joystick;
    }
  },
  methods: {
    Stringify(object) {
      return JSON.stringify(object,null, " ")
    },
    CalcLeftRightAvg(){
      this.leftRightCount = {left:0, right:0}
      this.$store.state.space.players.forEach(p => {
        
        if(p.leftRight?.direction == "left"){
          this.leftRightCount.left++
        }
        if(p.leftRight?.direction == "right"){
          this.leftRightCount.right++
        }
      });
      
      setTimeout(() => {
        this.CalcLeftRightAvg()
      }, 500);
    }
  },
  mounted() {

    var controller = this.$parent.experience.component[0].controller

    if (controller.type == 'gyro') {
      this.controller = {
        type: 'gyro'
      }
    }

    if (controller.type == 'leftRight') {
      this.controller = {
        type: 'leftRight'
      }
      this.CalcLeftRightAvg()
    }

    if (controller.type == 'joystick') {
      this.controller = {
        type: 'joystick',
        circularJoystick: controller.circularJoystick,
        buttonsJoystick: controller.buttonsJoystick
      }
    }

  },
  sockets: {
    GetJoystickData(data) {
      const player = this.$store.state.space.players.find(x => x.dbid == data.dbid);
      player.joystick = {
        x: data.x.toFixed(5),
        y: data.y.toFixed(5),
        angle: data.angle,
        radians: data.radians.toFixed(5)
      }
      console.log(player.username, player.joystick);
      this.$forceUpdate();
    },
    GetGyroData(data){
      const player = this.$store.state.space.players.find(x => x.dbid == data.dbid);

      player.gyro = {
        x: data.x.toFixed(5),
        y: data.y.toFixed(5)
      }

      this.$forceUpdate();
    },
    UpdateLeftRight(data){
      const player = this.$store.state.space.players.find(x => x.dbid == data.dbid);
      player.leftRight = { direction: data.direction }
      this.$forceUpdate();
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.jugadores {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: flex-start;

  & > div {
    display: contents;

    &:nth-child(3n):after {
      content: '';
      width: 100%;
    }

    & > div {
      width: 31.5%;
      margin: 1rem;
      background: hsl(0deg, 0%, 96%);
    }

  }

}

.player-label {
  background: $grey-lighter;
  display: flex;
  gap: 0.5rem;

  .playerAvatarContainer {
    display: inline-block;
  }
}

</style>